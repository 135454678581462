
import {
    cloudLicenseMessageKey,
    cloudLicenseKey,
    notGeneratedAvailableRedeemKey,
    errorOccurredRequestKey,
    redeemNotAvailableErrorKey,
    cannotGenerateMessageKey,
    cannotGenerateKey,
    licenseFailedGenerateKey,
    licenseErrorFailedEmailKey,
    licenseErrorTimeoutKey,
    licenseProgressTimeoutKey,
    requestProcessingKey,
    requestProcessingMessageKey,
    processingCompletedKey,
    licensesReadyKey,
    emailSuccessKey,
    emailReceiveKey
} from '../../../assets/text/MultilingualText.js';


export const GetResponseText = (responseEvent) => {
    let alertType;
    let title;
    let message;

    switch (responseEvent) {
        case 'requestLicenseInProgress':
            alertType = 'info'
            title = requestProcessingKey
            message = requestProcessingMessageKey
            break;
        case 'requestLicenseSuccess':
            alertType = 'success'
            title = processingCompletedKey
            message = licensesReadyKey
            break;
        case 'cloudAlert':
            alertType = 'info'
            title = cloudLicenseKey
            message = cloudLicenseMessageKey
            break;
        case 'supportError':
            alertType = 'error'
            title = licenseFailedGenerateKey
            message = licenseErrorFailedEmailKey
            break;
        case 'timeoutError':
            alertType = 'error'
            title = licenseErrorTimeoutKey
            message = licenseProgressTimeoutKey
            break;
        case 'generationError':
            alertType = 'error'
            title = errorOccurredRequestKey
            message = redeemNotAvailableErrorKey
            break;
        case 'qtyError':
            alertType = 'error'
            title = cannotGenerateKey
            message = cannotGenerateMessageKey
            break;
        case 'entitlementError':
            alertType = 'error'
            title = errorOccurredRequestKey
            message = notGeneratedAvailableRedeemKey
            break;
        case 'emailSuccess':
            alertType = 'success'
            title = emailSuccessKey
            message = emailReceiveKey
            break;
        default:
            alertType = 'error'
            title = errorOccurredRequestKey
            message = notGeneratedAvailableRedeemKey
    }

    return {
        alertType: alertType,
        title: title,
        message: message
    }
}
