import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import {useContext} from "react";
import UserContext from "./UserContext";
import {useLocalization} from "@progress/kendo-react-intl";
import {areYouSureKey, cancelKey, confirmKey, mainMessages, processLostKey} from "../../assets/text/MultilingualText";

export const StepWarningModal = (props) => {
    const {
        showWarningModal,
        setShowWarningModal,
        changeStepActivation
    } = props

    const {
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    return (
        showWarningModal &&
        <Dialog
            className={"ksm-dialog"}
            title={localization.toLanguageString(areYouSureKey, mainMessages[siteLanguageDefault][areYouSureKey])}
            onClose={() => {
                setShowWarningModal(false)
            }}
            width={'35rem'}
        >
            <div style={{height:'3rem', display: 'flex', alignItems: 'center'}}>
                {localization.toLanguageString(processLostKey, mainMessages[siteLanguageDefault][processLostKey])}
            </div>
            <DialogActionsBar layout="center">
                <Button
                    className={'ksm-internal-button-primary'}
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"outline"}
                    rounded={"small"}
                    type={"button"}
                    onClick={() => {
                        setShowWarningModal(false)
                    }}
                >
                    {localization.toLanguageString(cancelKey, mainMessages[siteLanguageDefault][cancelKey])}
                </Button>
                <Button
                    className={'ksm-internal-button-primary'}
                    themeColor={"primary"}
                    size={"large"}
                    fillMode={"solid"}
                    rounded={"small"}
                    type={"button"}
                    onClick={() => {
                        setShowWarningModal(false);
                        changeStepActivation(0, false);
                    }}
                >
                    {localization.toLanguageString(confirmKey, mainMessages[siteLanguageDefault][confirmKey])}
                </Button>
            </DialogActionsBar>
        </Dialog>
    )
}