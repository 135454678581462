import React, {createRef, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import config from '../config.js';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/ViewExpiringLicenseSupport.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import Spinner from '../components/common/Spinner.js';
import ExpiringLicenseSupportGrid from '../components/ExpiringLicenseSupportView/ExpiringLicenseSupportGrid.js';

// kendo react
import {RadioGroup} from '@progress/kendo-react-inputs';
import {Button} from '@progress/kendo-react-buttons';
import {ExcelExport, ExcelExportColumn} from '@progress/kendo-react-excel-export';
import {Tooltip} from '@progress/kendo-react-tooltip';
import {fileExcelIcon} from '@progress/kendo-svg-icons';
import {orderBy} from '@progress/kendo-data-query';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    hostIdKey,
    mainMessages,
    descriptionKey,
    licenseTypeKey,
    termKey,
    exportAllExcelKey,
    expiringLicenseSupportKey,
    seeExpirationsWithinKey,
    allExpirationKey,
    thirtyDaysExpirationKey,
    sixtyDaysExpirationKey,
    oneHundredTwentyDaysExpirationKey,
    productKey,
    activationCodeKey,
    qtyKey,
    licenseExpirationDateKey,
    supportExpirationDateKey,
    daysLeftKey,
    orderCertificateKey,
    enterpriseAgreementsNotIncludedKey,
    pleaseUseLicenseViewKey
} from '../assets/text/MultilingualText.js';
import Alert from "../components/common/Alert";

const initialSort = [
    {
        field: 'days_left',
        dir: 'asc'
    }
];
function ExpiringLicenseSupportView(props) {
    const {
        accessToken, siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const [isLoading, setIsLoading] = useState(false);

    const [expirationRanges] = useState([{
        label: localization.toLanguageString(allExpirationKey, mainMessages[siteLanguageDefault][allExpirationKey]),
        value: 0,
    }, {
        label: localization.toLanguageString(thirtyDaysExpirationKey, mainMessages[siteLanguageDefault][thirtyDaysExpirationKey]),
        value: 30,
    }, {
        label: localization.toLanguageString(sixtyDaysExpirationKey, mainMessages[siteLanguageDefault][sixtyDaysExpirationKey]),
        value: 60,
    }, {
        label: localization.toLanguageString(oneHundredTwentyDaysExpirationKey, mainMessages[siteLanguageDefault][oneHundredTwentyDaysExpirationKey]),
        value: 120,
    }]);
    const [expiration, setExpiration] = useState(expirationRanges[0]?.value);

    const [licenseSupports, setLicenseSupports] = useState([]);
    const [filteredLicenseSupports, setFilteredLicenseSupports] = useState([]);

    const [isEA, setIsEA] = useState(true);

    // kendo react excel export
    const [userExcelData, setUserExcelData] = useState([]);
    const [excelFileName, setExcelFileName] = useState("");
    const [disableExportExcel, setDisableExportExcel] = useState(true)

    const _exporter = createRef();
    const exportExcel = () => {
        setIsLoading(true);
        if (_exporter.current) {
            save(_exporter);
        }
    }

    // custom save excel export to show new lines
    const save = (component) => {
        const options = component.current.workbookOptions();
        const rows = options.sheets[0].rows;
        rows.forEach(row => {
            if (row.type === 'data') {
                row.cells.forEach((cell, index) => {
                    if (index === 0) {
                        cell.wrap = true;
                    }
                });
            }
        });
        component.current.save(options);
    }

    /*
     * getExpiringLicenseSupportData() gets all expiring license support data
    */
    function getExpiringLicenseSupportData() {
        let headers = {
            'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken
        };

        setIsLoading(true);

        axios.get(
            config.view_expiring_license_support.LICENSES_SUPPORT,
            {headers: headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.result || [];
                    let eAExists = data.some(d => d.order_source === "EA");

                    data = data.filter(d => d.order_source !== "EA");
                    data.forEach(d => {
                        let licenseExpirationDate = d.license_expiration_date;
                        let supportExpirationDate = d.support_expiration_date;
                        d.license_expiration_date = d.license_expiration_date && moment(licenseExpirationDate).isValid() ? new Date(licenseExpirationDate) : null;
                        d.support_expiration_date = d.support_expiration_date && moment(supportExpirationDate).isValid() ? new Date(supportExpirationDate) : null;

                        d.product_number_subscription = (d.product_number !== d.sus_product_number && d.sus_product_number) ? d.product_number + '\n' + d.sus_product_number : d.product_number;
                        d.order_number_certificate = d.order_number + '/' + d.o_certificate_id;
                    });
                    data = orderBy(data, initialSort);

                    setLicenseSupports(data);
                    setFilteredLicenseSupports(data);

                    // set excel data
                    setDisableExportExcel(false);
                    setExcelFileName("KSM_EXPIRING_LICENSE_SUPPORT_RESULT.xlsx");
                    setUserExcelData(data);

                    // set is ea flag
                    eAExists ? setIsEA(true): setIsEA(false);
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to GET Expiring License Support Data", error);
                setDisableExportExcel(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    // get data based on expiration
    useEffect(() => {
        let filtered = [...licenseSupports].filter(l => l.days_left <= expiration);
        !expiration ? setFilteredLicenseSupports(licenseSupports) : setFilteredLicenseSupports(filtered);
    }, [expiration]); // eslint-disable-line react-hooks/exhaustive-deps

    // on load
    useEffect(() => {
        getExpiringLicenseSupportData();
    }, []);

    return (
        <>
            <PageHeader/>
            {isLoading ? <Spinner/> : <></>}
            <div className={"ksm-page-container"}>
                <div className={"k-h5 mb-2"}>
                    {localization.toLanguageString(expiringLicenseSupportKey, mainMessages[siteLanguageDefault][expiringLicenseSupportKey])}
                </div>
                <Row className={"mb-2"}>
                    <Col className={"d-flex align-items-center"}>
                        <b>{localization.toLanguageString(seeExpirationsWithinKey, mainMessages[siteLanguageDefault][seeExpirationsWithinKey])}</b>
                        <RadioGroup
                            className={"ms-3"}
                            onChange={(e) => setExpiration(e.value)}
                            value={expiration}
                            data={expirationRanges}
                            layout={'horizontal'}
                        />
                    </Col>
                    <Col className={"d-flex justify-content-end"}>
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <Button
                                title={localization.toLanguageString(exportAllExcelKey, mainMessages[siteLanguageDefault][exportAllExcelKey])}
                                themeColor={"tertiary"}
                                fillMode={"solid"}
                                svgIcon={fileExcelIcon}
                                disabled={disableExportExcel}
                                onClick={exportExcel}
                            />
                        </Tooltip>
                        <ExcelExport
                            data={userExcelData}
                            fileName={excelFileName}
                            ref={_exporter}
                            onExportComplete={() => setIsLoading(false)}
                        >
                            <ExcelExportColumn
                                field="product_number_subscription"
                                title={localization.toLanguageString(productKey, mainMessages[siteLanguageDefault][productKey])}
                            />
                            <ExcelExportColumn
                                field="activation_code"
                                title={localization.toLanguageString(activationCodeKey, mainMessages[siteLanguageDefault][activationCodeKey])}
                            />
                            <ExcelExportColumn
                                field="description"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                            />
                            <ExcelExportColumn
                                field="license_term"
                                title={localization.toLanguageString(termKey, mainMessages[siteLanguageDefault][termKey])}
                            />
                            <ExcelExportColumn
                                field="license_type"
                                title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                            />
                            <ExcelExportColumn
                                field="qty"
                                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                            />
                            <ExcelExportColumn
                                field="license_expiration_date"
                                title={localization.toLanguageString(licenseExpirationDateKey, mainMessages[siteLanguageDefault][licenseExpirationDateKey])}
                            />
                            <ExcelExportColumn
                                field="support_expiration_date"
                                title={localization.toLanguageString(supportExpirationDateKey, mainMessages[siteLanguageDefault][supportExpirationDateKey])}
                            />
                            <ExcelExportColumn
                                field="days_left"
                                title={localization.toLanguageString(daysLeftKey, mainMessages[siteLanguageDefault][daysLeftKey])}
                            />
                            <ExcelExportColumn
                                field="host_id"
                                title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                            />
                            <ExcelExportColumn
                                field="order_number_certificate"
                                title={localization.toLanguageString(orderCertificateKey, mainMessages[siteLanguageDefault][orderCertificateKey])}
                            />
                        </ExcelExport>
                    </Col>
                </Row>
                <Row className={"mb-2"}>
                    <Col>
                        <ExpiringLicenseSupportGrid licenseSupports={filteredLicenseSupports}/>
                    </Col>
                </Row>
                {isEA && <Row>
                    <Col>
                        <Alert
                            type={'info'}
                            title={localization.toLanguageString(enterpriseAgreementsNotIncludedKey, mainMessages[siteLanguageDefault][enterpriseAgreementsNotIncludedKey])}
                            message={localization.toLanguageString(pleaseUseLicenseViewKey, mainMessages[siteLanguageDefault][pleaseUseLicenseViewKey])}
                        />
                    </Col>
                </Row>}
            </div>
        </>
    );
}

export default ExpiringLicenseSupportView;