import React, {useCallback, useContext, useRef} from 'react';
import UserContext from '../../common/UserContext.js';

// components
import {PopoverContext} from '../LicenseDownload.js';
import {downloadLicenses} from '../../common/utilities.js';

// react strap
import {Col, Row} from 'reactstrap';

// kendo react
import {Tooltip} from '@progress/kendo-react-tooltip';
import {Loader} from '@progress/kendo-react-indicators';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    mainMessages,
    downloadLicensesKey,
    newlyAssignedProductsKey,
    loadingGenerationKey
} from '../../../assets/text/MultilingualText.js';
import {DownloadIcon, InfoIcon, WarningErrorIcon} from "../../common/icons";


export const PanelbarHeader = (props) => {
    const {
        hostJSON,
        setIsLoading,
        handleBeforeUnload,
        showAssignedCount = true,
        publicCall = false,
        xAuthToken = null,
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    //Sets the popover reference based on mouse over
    const popoverRef = useRef(null);
    const {onMouseOver} = useContext(PopoverContext);

    const handleMouseOver = useCallback(() => {
        const icons = hostJSON.icons
        onMouseOver({
            show: true,
            popoverRef: popoverRef,
            timeoutError: icons.timeoutError,
            cloudAlert: icons.cloudAlert,
            supportError: icons.supportError,
            generationError: icons.generationError,
            qtyError: icons.qtyError,
            entitlementError: icons.entitlementError,
        });
    }, [onMouseOver, hostJSON.icons]);
    const handleMouseOut = useCallback(() => {
        const icons = hostJSON.icons;
        onMouseOver({
            show: false,
            popoverRef: popoverRef,
            timeoutError: icons.timeoutError,
            cloudAlert: icons.cloudAlert,
            supportError: icons.supportError,
            generationError: icons.generationError,
            qtyError: icons.qtyError,
            entitlementError: icons.entitlementError,
        });
    }, [onMouseOver, hostJSON.icons]);

    return (
        <>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <Col
                    style={{
                        flex: '0 0 auto',
                        width: 'auto'
                    }}
                >
                    <div
                        className={"k-h5"}
                        style={{
                            color: 'white',
                            margin: '0'
                        }}
                    >
                        {hostJSON.panelBarTitle}
                    </div>
                </Col>
                {showAssignedCount && (
                    <Col
                        style={{
                            flex: '0 0 auto',
                            width: 'auto'
                        }}
                    >
                        <div
                            style={{
                                fontSize: '1rem',
                                fontWeight: '300',
                                color: 'white',
                                lineHeight: '1.5rem'
                            }}
                        >
                            {hostJSON.products.length} {localization.toLanguageString(newlyAssignedProductsKey, mainMessages[siteLanguageDefault][newlyAssignedProductsKey])}
                        </div>
                    </Col>
                )}
                <Col>
                    <div
                        style={{
                            float: 'right'
                        }}
                    >
                        <div>
                            {hostJSON.icons.loading && (
                                <Tooltip
                                    anchorElement="pointer"
                                    showCallout={true}
                                    parentTitle={true}
                                    openDelay={0}
                                    filter={(e) => {
                                        const classNames = ['k-loader k-loader-md k-loader-primary k-loader-pulsing-2', 'k-loader-segment', 'k-loader-canvas'];
                                        if (classNames.includes(e.className)) {
                                            return true;
                                        }
                                    }}
                                >
                                    <div
                                        title={localization.toLanguageString(loadingGenerationKey, mainMessages[siteLanguageDefault][loadingGenerationKey])}
                                        style={{
                                            paddingBottom: '1px'
                                        }}
                                    >
                                        <Loader
                                            type={'pulsing'}
                                            style={{
                                                color: 'white'
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {hostJSON.icons.download && (
                                <Tooltip
                                    anchorElement="pointer"
                                    showCallout={true}
                                    parentTitle={true}
                                    openDelay={0}
                                >
                                    <div
                                        title={localization.toLanguageString(downloadLicensesKey, mainMessages[siteLanguageDefault][downloadLicensesKey])}
                                        onClick={() => {
                                            if (publicCall) {
                                                downloadLicenses([hostJSON['transaction_id']], xAuthToken, setIsLoading, handleBeforeUnload, publicCall);
                                            } else {
                                                downloadLicenses([hostJSON['transaction_id']], accessToken, setIsLoading, handleBeforeUnload);
                                            }
                                        }}
                                    >
                                        <DownloadIcon
                                            size={'medium'}
                                            style={{
                                                color: 'white',
                                                marginTop: '0.35rem',
                                                marginLeft: '0.35rem',
                                                marginRight: '0.35rem',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                            {!hostJSON.icons.download && !hostJSON.icons.cloudAlert && !hostJSON.icons.loading && (
                                    <div onMouseOver={handleMouseOver}
                                         onMouseOut={handleMouseOut}
                                         ref={popoverRef}
                                    >
                                        <WarningErrorIcon
                                            size={'medium'}
                                            style={{
                                                color: 'var(--keysight-pink)',
                                                marginTop: '0.35rem',
                                                marginLeft: '0.35rem',
                                                marginRight: '0.35rem',
                                            }}
                                        />
                                    </div>
                                )}
                            {hostJSON.icons.cloudAlert && (
                                <div
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                    ref={popoverRef}>
                                    <InfoIcon
                                        size={'medium'}
                                        style={{
                                            color: 'white',
                                            marginTop: '0.35rem',
                                            marginLeft: '0.35rem',
                                            marginRight: '0.35rem',
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}