import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';
import uuid from 'react-uuid';

// react router
import {useLocation} from 'react-router-dom';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import Alert from '../common/Alert.js';
import {EmailDialog} from '../common/EmailModal.js';
import {LicenseDownloadPopover} from './LicenseDownload/Popovers.js';
import {PanelbarHeader} from './LicenseDownload/PanelbarHeader.js';
import {LicensePreviewDownloadGrid} from './LicensePreview/Grid.js';
import {downloadLicenses} from '../common/utilities.js';

// kendo react
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {Button} from '@progress/kendo-react-buttons';

// multilingual
import {useLocalization} from "@progress/kendo-react-intl";
import {
    mainMessages,
    downloadAllLicensesKey,
    mailAllKey,
    newLicensedProductsKey,
    assignMoreKey
} from '../../assets/text/MultilingualText.js';
import {Tooltip} from "@progress/kendo-react-tooltip";
import {DownloadIcon, IconButton, MailIcon} from "../common/icons";
import {GetResponseText} from "../common/RequestLicense/GetResponseText";
import {RequestPopovers} from "../common/RequestLicense/RequestPopovers";
import {RequestAlerts} from "../common/RequestLicense/RequestAlerts";

// context to store popover reference, when to show, and which popover to show
export const PopoverContext = createContext({
    onMouseOver: () => null,
});

function LicenseDownload(props) {
    const {
        changeStepActivation,
        assignedProducts,
        setAssignedProducts,
        requestedProducts,
        setRequestedProducts,
        handleBeforeUnload,
        setIsLoading,
        stepperItems,
        setStepperItems,
        selectedProducts
    } = props
    const {
        accessToken,
        siteLanguageDefault,
        timeout
    } = useContext(UserContext);
    const localization = useLocalization();

    const [showPopover, setShowPopover] = useState(false);
    const [popoverType, setPopoverType] = useState('generalError');
    const [popoverRef, setPopoverRef] = useState();
    const [showEmailModal, setShowEmailModal] = useState(false);

    const [disableEmailDownloadButtons, setDisableEmailDownloadButtons] = useState(true);
    const [disableAssignMoreStepperButtons, setDisableAssignMoreStepperButtons] = useState(true);

    const initIsVisible = {
        requestLicenseInProgress: true,
        requestLicenseSuccess: false,
        cloudAlert: false,
        supportError: false,
        timeoutError: false,
        generationError: false,
        qtyError: false,
        entitlementError: false,
        emailSuccess: false,
    }
    const [isVisibleAlerts, setIsVisibleAlerts] = useState(initIsVisible);

    //Show success alert
    useEffect(() => {
        const requiredCount = requestedProducts.length;
        let successCount = 0

        requestedProducts.forEach(host => {
            if (host.icons.download || host.icons.cloudAlert) {
                successCount++
            }
        })

        if (successCount === requiredCount) {
            setIsVisibleAlerts(isVisibleAlerts => ({
                ...isVisibleAlerts,
                requestLicenseInProgress: false,
                requestLicenseSuccess: true
            }));
        }

    }, [requestedProducts])


    const {search} = useLocation();

    //Request new license for every host in assign products state
    useEffect(() => {
        async function requestNewLicense(host, index) {
            let headers = {
                'Authorization': 'Bearer ' + accessToken
            };

            let cleanProducts = JSON.parse(JSON.stringify(host['products']));
            cleanProducts.forEach(product => {
                delete product['inEdit']
                delete product['unique_id']
                delete product['transaction_id']
            });
            let formData = new FormData();
            formData.append('File', host['file']);

            let query = new URLSearchParams(search);
            const orderID = query.get('orderID');

            let data = {
                transaction_id: host['transaction_id'],
                hostid_type: host['host_id_type'],
                products: cleanProducts,
                host_id: host['host_id'],
                serial_id: host['serial_id'],
                target_orders: host['target_orders'],
                order_id: orderID
            };

            formData.append('Data', JSON.stringify(data));

            let savedHost = requestedProducts[index];
            axios.post(
                config.request_license.REQUEST_NEW_LICENSE,
                formData,
                {headers: headers, timeout: timeout}
            )
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data['license_generated'] === 'YES') {
                            let licenseType = response.data['license_type']
                            switch (licenseType) {
                                case "FILE":
                                case "KEY":
                                    savedHost['icons']['download'] = true;
                                    savedHost['icons']['loading'] = false;
                                    break;
                                case "CLOUD":
                                    savedHost['icons']['cloudAlert'] = true;
                                    savedHost['icons']['loading'] = false;
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    setRequestedProducts([...requestedProducts]);

                    let index = assignedProducts.findIndex(host => host.transaction_id === savedHost.transaction_id)
                    assignedProducts.splice(index, 1)
                    setAssignedProducts([...assignedProducts])
                })
                .catch((error) => {
                    console.log("ERROR: Failed to request new license", error, error?.response, error?.code);
                    setIsVisibleAlerts(isVisibleAlerts => ({...isVisibleAlerts, requestLicenseInProgress: false}));
                    savedHost['icons']['loading'] = false;
                    let retry = false
                    if (error?.code === 'ECONNABORTED' || error?.code === 'ERR_NETWORK') { //Axios timeout or Cloudfare timeout
                        setIsVisibleAlerts(isVisibleAlerts => ({...isVisibleAlerts, timeoutError: true}));
                        savedHost['icons']['timeoutError'] = true;
                    } else if (!error.response) { //Internet disconnected with no response body
                        setIsVisibleAlerts(isVisibleAlerts => ({...isVisibleAlerts, generationError: true}));
                        savedHost['icons']['generationError'] = true;
                    } else { // contains response body
                        if (error.response?.status === 408 || error.response?.status === 504) { //Backend Nginx API & gateway timeout
                            setIsVisibleAlerts(isVisibleAlerts => ({...isVisibleAlerts, timeoutError: true}));
                            savedHost['icons']['timeoutError'] = true;
                        }
                        if (error.response?.hasOwnProperty('data')) {
                            let errorCode = error.response.data['error_code'].toUpperCase();
                            switch (errorCode) {
                                case "MOD_LICENSE_228": //Plugin Fail
                                    setIsVisibleAlerts(isVisibleAlerts => ({...isVisibleAlerts, supportError: true}));
                                    savedHost['icons']['supportError'] = true;
                                    break;
                                case "MOD_LICENSE_220": //Qty consumed & user can retry
                                    setIsVisibleAlerts(isVisibleAlerts => ({...isVisibleAlerts, qtyError: true}));
                                    savedHost['icons']['qtyError'] = true;
                                    retry = true
                                    break;
                                default: //Backend/DB error/rollback & user can retry
                                    setIsVisibleAlerts(isVisibleAlerts => ({
                                        ...isVisibleAlerts,
                                        entitlementError: true
                                    }));
                                    savedHost['icons']['entitlementError'] = true;
                                    retry = true
                                    break
                            }
                        }
                    }
                    setRequestedProducts([...requestedProducts]);

                    //Hosts that can retry should appear again in license preview
                    let index = assignedProducts.findIndex(host => host.transaction_id === savedHost.transaction_id)
                    if (retry) {
                        assignedProducts[index].transaction_id = uuid()
                    } else {
                        assignedProducts.splice(index, 1)
                    }
                    setAssignedProducts([...assignedProducts])

                    if (!error.response) {
                        emailSupport(host);
                    }
                });
        }

        requestedProducts.forEach((host, index) => {
            requestNewLicense(host, index);
        })
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    async function emailSupport(host) {
        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let cleanProducts = JSON.parse(JSON.stringify(host['products']));
        cleanProducts.forEach(product => {
            delete product['inEdit'];
            delete product['unique_id'];
            delete product['transaction_id'];
        })
        let formData = new FormData();
        formData.append('File', host['file']);

        let query = new URLSearchParams(search);
        const orderID = query.get('orderID');

        let data = {
            "transaction_id": host['transaction_id'],
            "hostid_type": host['host_id_type'],
            "products": cleanProducts,
            "host_id": host['host_id'][0],
            "serial_id": host['serial_id'],
            "order_id": orderID
        };

        formData.append('Data', JSON.stringify(data));
        axios.post(
            config.request_license.EMAIL_SUPPORT,
            formData,
            {headers: headers}
        )
            .then((response) => {
            })
            .catch((error) => {
            });
    }

    const [downloadableTransactionIDs, setDownloadableTransactionIDs] = useState([]);

    //make update subscription tie call
    async function updateSubscriptionTie(transactionIDs) {
        let headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let data = {
            "transaction_ids": transactionIDs
        };
        axios.post(
            config.request_license.UPDATE_SUBSCRIPTION_TIE,
            data,
            {
                headers: headers
            }
        )
            .then((response) => {
                if (response.status === 200) {
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to update subscription tie: ", error);
            });
    }

    //Update Subscription Tie once all the hosts are finished requesting licenses
    useEffect(() => {
        let finishedLoading = true;
        let transactionIDs = [];
        requestedProducts.forEach(host => {
            if (host.icons.loading) {
                finishedLoading = false;
            }
            transactionIDs.push(host.transaction_id);
        })

        if (finishedLoading) {
            updateSubscriptionTie(transactionIDs);
        }

    }, [requestedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    //Keeps track of what transaction ids are downloadable
    useEffect(() => {
        let transactionIDs = [];
        requestedProducts.forEach(host => {
            if (host.icons.download) {
                transactionIDs.push(host.transaction_id);
            }
        })
        setDownloadableTransactionIDs(transactionIDs);
    }, [requestedProducts])

    //Download all license from all host with the download icon
    const downloadAllLicenses = () => {
        downloadLicenses(downloadableTransactionIDs, accessToken, setIsLoading, handleBeforeUnload);
    }

    //Enable or disable email/download selected license button
    useEffect(() => {
        let notFinishedLoading = false;
        let isDownloadable = false;
        requestedProducts.forEach(host => {
            //Enable buttons if all hosts are done loading and at least one downloadable host
            if (host.icons.loading) {
                notFinishedLoading = true;
            }

            if (host.icons.download) {
                isDownloadable = true;
            }
        })

        if (notFinishedLoading) {
            setDisableEmailDownloadButtons(true);
        } else {
            if (isDownloadable) {
                setDisableEmailDownloadButtons(false);
            } else {
                setDisableEmailDownloadButtons(true);
            }
        }
    }, [requestedProducts])

    //Enable or disable step 1 and assign more button
    useEffect(() => {
        let notFinishedLoading = false;

        requestedProducts.forEach(host => {
            //Enable buttons if all hosts are done loading
            if (host.icons.loading) {
                notFinishedLoading = true;
            }
        })
        let updateStepper = JSON.parse(JSON.stringify(stepperItems));

        if (notFinishedLoading || selectedProducts.filter(item => item.qty_available > 0).length === 0) {
            setDisableAssignMoreStepperButtons(true);
            updateStepper[0].disabled = true;
        } else {
            setDisableAssignMoreStepperButtons(false);
            updateStepper[0].disabled = false;
        }
        setStepperItems(updateStepper);
    }, [requestedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    //Enable step 1/2 if there are retry assigned products
    useEffect(() => {
        let notFinishedLoading = false;
        requestedProducts.forEach(host => {
            //Enable buttons if all hosts are done loading
            if (host.icons.loading) {
                notFinishedLoading = true;
            }
        })
        let updateStepper = JSON.parse(JSON.stringify(stepperItems));
        if (!notFinishedLoading && assignedProducts.length > 0) {
            updateStepper[1].disabled = false;
            setDisableAssignMoreStepperButtons(false);
            updateStepper[0].disabled = false;
        }
        setStepperItems(updateStepper);
    }, [assignedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    //Sets which popover to show and it's corresponding reference
    const handlePopoverMouseOver = useCallback(
        (event) => {
            if (event.show) {
                setShowPopover(true);
            } else {
                setShowPopover(false);
            }
            if (event.cloudAlert) {
                setPopoverType('cloudAlert');
            } else if (event.supportError) {
                setPopoverType('supportError');
            } else if (event.timeoutError) {
                setPopoverType('timeoutError');
            } else if (event.generationError) {
                setPopoverType('generationError');
            } else if (event.qtyError) {
                setPopoverType('qtyError');
            } else {
                setPopoverType('entitlementError');
            }

            setPopoverRef(event.popoverRef);
        },
        [setShowPopover, setPopoverType, setPopoverRef]
    );

    return (
        <>
            <div
                className={"k-h5"}
            >
                {localization.toLanguageString(newLicensedProductsKey, mainMessages[siteLanguageDefault][newLicensedProductsKey])}
            </div>
            <PopoverContext.Provider
                value={{
                    onMouseOver: handlePopoverMouseOver
                }}
            >
                {requestedProducts.length !== 0 && (
                    //Created a panel bar for each host because getting each
                    //panel requires use of isControlled, expanded, and children
                    // props and a new json structure to for the children prop.
                    // example of children json: https://www.telerik.com/kendo-react-ui/components/layout/panelbar/customization/
                    requestedProducts.map((item, index) => {
                        return (
                            <PanelBar
                                key={item.transaction_id}
                                expanded={['.0']}
                                isControlled={true}
                                className={'ksm-panelbar-default ksm-panelbar-no-arrow'}
                            >
                                <PanelBarItem
                                    title={<PanelbarHeader
                                        hostJSON={item}
                                        setIsLoading={setIsLoading}
                                        handleBeforeUnload={handleBeforeUnload}
                                    />}
                                >
                                    <LicensePreviewDownloadGrid products={item.products}/>
                                </PanelBarItem>
                            </PanelBar>
                        )
                    })
                )}
            </PopoverContext.Provider>
            <RequestPopovers
                type={popoverType}
                showPopover={showPopover}
                popoverRef={popoverRef}
            />
            <Row
                style={{
                    marginBottom: '0.938rem'
                }}
            >
                <Col>
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"outline"}
                        disabled={disableAssignMoreStepperButtons}
                        onClick={() => {
                            changeStepActivation(0)
                        }}
                    >
                        {localization.toLanguageString(assignMoreKey, mainMessages[siteLanguageDefault][assignMoreKey])}
                    </Button>
                </Col>
                <Col>
                    <RequestAlerts
                        isVisibleAlerts={isVisibleAlerts}
                        setIsVisibleAlerts={setIsVisibleAlerts}
                    />
                </Col>
                <Col>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            flexWrap: 'wrap',
                            gap: '0.5rem'
                        }}
                    >
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(mailAllKey, mainMessages[siteLanguageDefault][mailAllKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                disabled={disableEmailDownloadButtons}
                                onClick={() => {
                                    setShowEmailModal(true)
                                }}
                                Icon={MailIcon}
                            />
                        </Tooltip>
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <IconButton
                                title={localization.toLanguageString(downloadAllLicensesKey, mainMessages[siteLanguageDefault][downloadAllLicensesKey])}
                                themeColor={"primary"}
                                fillMode={"solid"}
                                size={"large"}
                                onClick={downloadAllLicenses}
                                disabled={disableEmailDownloadButtons}
                                Icon={DownloadIcon}
                            />
                        </Tooltip>
                    </div>
                </Col>
            </Row>
            {showEmailModal && (
                <EmailDialog
                    setShowEmailModal={setShowEmailModal}
                    setShowEmailSuccess={() => {
                        setIsVisibleAlerts(isVisibleAlerts => ({...isVisibleAlerts, emailSuccess: true}))
                    }}
                    transactionIDs={downloadableTransactionIDs}
                    setIsLoading={setIsLoading}
                    request_source='REQ_NEW_LICENSE'
                />
            )}
        </>
    );
}

export default LicenseDownload;