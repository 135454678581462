import React from "react";

import {
    addNewCertificatesKey,
    addNewCertificatesTooltipKey,
    checkInLicensesKey,
    checkInLicensesTooltipKey,
    checkOutLicensesKey,
    checkOutLicensesTooltipKey,
    deactivateLicensesKey,
    deactivateLicensesTooltipKey,
    downloadSoftwareUpdatesKey,
    downloadSoftwareUpdatesTooltipKey,
    enterpriseAgreementsKey,
    entitlementManagementKey,
    licenseManagementKey,
    manageHostsKey,
    manageHostsTooltipKey,
    manageEnterpriseAgreementsKey,
    manageEnterpriseAgreementsTooltipKey,
    licensesAndHostsKey,
    renewEESOFLicensesKey,
    renewEESOFLicensesTooltipKey,
    renewNemoLicensesKey,
    renewNemoLicensesTooltipKey,
    renewSoftwareSubscriptionsKey,
    renewSoftwareSubscriptionsTooltipKey,
    requestNewLicensesKey,
    requestNewLicensesTooltipKey,
    shareMyCertificatesKey,
    shareMyCertificatesTooltipKey,
    softwareAccessLicensesKey,
    softwareUpdatesKey,
    softwareUsageReportKey,
    softwareUsageReportTooltipKey,
    sWAccessLicensesKey,
    sWAccessLicensesTooltipKey,
    syncLicensesKey,
    syncLicensesTooltipKey,
    viewByProductHostKey,
    viewByProductHostTooltipKey,
    viewDownloadLicensesKey,
    viewDownloadLicensesTooltipKey,
    viewMyCertificatesKey,
    viewCertificatesTooltipKey,
    viewExpiringLicenseSupportKey,
    viewExpiringLicenseSupportTooltipKey,
    enterpriseAgreementsDashboardKey,
    enterpriseAgreementsDashboardTooltipKey
} from "./assets/text/MultilingualText";
import {ReactComponent as EntitlementManagementIcon} from "./assets/icons/entitlement-management.svg";
import {ReactComponent as LicenseManagementIcon} from "./assets/icons/license-management.svg";
import {ReactComponent as SoftwareUpdatesIcon} from "./assets/icons/software-updates.svg";
import {ReactComponent as MyLicensesAndHostsIcon} from "./assets/icons/my-licenses-and-hosts.svg";
import {ReactComponent as SoftwareAccessLicensesIcon} from "./assets/icons/software-access-licenses.svg";
import {ReactComponent as EnterpriseAgreementsIcon} from "./assets/icons/enterprise-agreements.svg";

const iconStyle = {
    width: "3.125rem",
    height: "3.125rem"
}

export const tileHeader = {
    "ENTITLEMENT MANAGEMENT": {
        icon: <EntitlementManagementIcon style={iconStyle}/>,
        titleKey: entitlementManagementKey,
    },
    "LICENSE MANAGEMENT": {
        icon: <LicenseManagementIcon style={iconStyle}/>,
        titleKey: licenseManagementKey,
    },
    "SOFTWARE UPDATES": {
        icon: <SoftwareUpdatesIcon style={iconStyle}/>,
        titleKey: softwareUpdatesKey,
    },
    "MY LICENSES & HOSTS": {
        icon: <MyLicensesAndHostsIcon style={iconStyle}/>,
        titleKey: licensesAndHostsKey,
    },
    "SOFTWARE ACCESS LICENSES": {
        icon: <SoftwareAccessLicensesIcon style={iconStyle}/>,
        titleKey: softwareAccessLicensesKey,
    },
    "ENTERPRISE AGREEMENTS": {
        icon: <EnterpriseAgreementsIcon style={iconStyle}/>,
        titleKey: enterpriseAgreementsKey,
    },
}

export const linkMappings = {
    "Add New Certificate": {
        subMenuTitleKey: addNewCertificatesKey,
        tooltipKey: addNewCertificatesTooltipKey,
        actionLink: '/add-certificate',
    },
    "View My Certificates": {
        subMenuTitleKey: viewMyCertificatesKey,
        tooltipKey: viewCertificatesTooltipKey,
        actionLink: '/view-certificate',
    },
    "Share My Certificates": {
        subMenuTitleKey: shareMyCertificatesKey,
        tooltipKey: shareMyCertificatesTooltipKey,
        actionLink: '',
    },
    "Request New Licenses": {
        subMenuTitleKey: requestNewLicensesKey,
        tooltipKey: requestNewLicensesTooltipKey,
        actionLink: '/request-license',
    },
    "Deactivate Licenses": {
        subMenuTitleKey: deactivateLicensesKey,
        tooltipKey: deactivateLicensesTooltipKey,
        actionLink: '/deactivate-license',
    },
    "Sync Licenses": {
        subMenuTitleKey: syncLicensesKey,
        tooltipKey: syncLicensesTooltipKey,
        actionLink: '/sync-license',
    },
    "Renew Support For My Nemo Licenses": {
        subMenuTitleKey: renewNemoLicensesKey,
        tooltipKey: renewNemoLicensesTooltipKey,
        actionLink: '/renew-nemo',
    },
    "Renew Support For My Standalone Software Subscriptions": {
        subMenuTitleKey: renewSoftwareSubscriptionsKey,
        tooltipKey: renewSoftwareSubscriptionsTooltipKey,
        actionLink: '/renew-standalone',
    },
    "Renew My EESOF EDA Licenses": {
        subMenuTitleKey: renewEESOFLicensesKey,
        tooltipKey: renewEESOFLicensesTooltipKey,
        actionLink: '/renew-eesof'
    },
    "Download Software Updates": {
        subMenuTitleKey: downloadSoftwareUpdatesKey,
        tooltipKey: downloadSoftwareUpdatesTooltipKey,
        actionLink: '/software-updates'
    },
    "View Licenses by Product or Host": {
        subMenuTitleKey: viewByProductHostKey,
        tooltipKey: viewByProductHostTooltipKey,
        actionLink: '/view-product'
    },
    "View Expiring Licenses and Support": {
        subMenuTitleKey: viewExpiringLicenseSupportKey,
        tooltipKey: viewExpiringLicenseSupportTooltipKey,
        actionLink: '/view-expiring-licenses-support'
    },
    "Host Management": {
        subMenuTitleKey: manageHostsKey,
        tooltipKey: manageHostsTooltipKey,
        actionLink: '/host-config'
    },
    "Check Out Licenses": {
        subMenuTitleKey: checkOutLicensesKey,
        tooltipKey: checkOutLicensesTooltipKey,
        actionLink: '/sa-checkout'
    },
    "Check In Licenses": {
        subMenuTitleKey: checkInLicensesKey,
        tooltipKey: checkInLicensesTooltipKey,
        actionLink: '/sa-checkin'
    },
    "View/Download Licenses": {
        subMenuTitleKey: viewDownloadLicensesKey,
        tooltipKey: viewDownloadLicensesTooltipKey,
        actionLink: '/sa-download'
    },
    "Software Usage Report": {
        subMenuTitleKey: softwareUsageReportKey,
        tooltipKey: softwareUsageReportTooltipKey,
        actionLink: '/sa-report'
    },
    "Manage My SW Access licenses": {
        subMenuTitleKey: sWAccessLicensesKey,
        tooltipKey: sWAccessLicensesTooltipKey,
        actionLink: ''
    },
    "Manage My Enterprise Agreements": {
        subMenuTitleKey: manageEnterpriseAgreementsKey,
        tooltipKey: manageEnterpriseAgreementsTooltipKey,
        actionLink: '/ea-summary'
    },
    "EA Dashboard": {
        subMenuTitleKey: enterpriseAgreementsDashboardKey,
        tooltipKey: enterpriseAgreementsDashboardTooltipKey,
        actionLink: '/ea-dashboard'
    },
}

