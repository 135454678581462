import React, {useContext} from 'react';
import UserContext from '../components/common/UserContext.js';

// css
import '../assets/css/Help.css';

// reactstrap
import {Col, Row} from 'reactstrap';

// components
import PageHeader from '../components/common/PageHeader.js';
import HelpMenu from '../components/Help/HelpMenu.js';
import {toHtml} from '../components/common/utilities.js';

// images
import KsmExpireLicenseSupportSort from '../assets/img/Help/ksm-expire-license-support-sort.png'
import KsmExpireLicenseSupportSort1 from '../assets/img/Help/ksm-expire-license-support-sort-1.png'
import KsmExpireLicenseSupportSort2 from '../assets/img/Help/ksm-expire-license-support-sort-2.png'
import KsmExpireLicenseSupportSort3 from '../assets/img/Help/ksm-expire-license-support-sort-3.png'
import KsmExpireLicenseSupportSort4 from '../assets/img/Help/ksm-expire-license-support-sort-4.png'

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {Text} from '../components/common/MultilingualText.js';
import {
    frequentlyAskedQuestionsKey,
    mainMessages,
    viewExpiringLicenseSupportKey,
    LicenseSupportExpirationOverviewKey,
    SortAndFiltersKey,
    LicenseSupportExpirationKey,
    helpLicenseSupportExpirationOverviewSectionOneKey,
    helpLicenseSupportExpirationOverviewSectionTwoKey,
    helpLicenseSupportExpirationQAKey
} from '../assets/text/MultilingualText.js';


function ExpiringLicenseSupportViewHelp(props) {
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const helpContent = {
        'header': {
            key: viewExpiringLicenseSupportKey,
            default: mainMessages[siteLanguageDefault][viewExpiringLicenseSupportKey]
        },
        'topics': [
            {
                key: LicenseSupportExpirationOverviewKey,
                default: mainMessages[siteLanguageDefault][LicenseSupportExpirationOverviewKey],
                hash: "#License-and-Support-Expiration-Overview"
            },
            {
                key: SortAndFiltersKey,
                default: mainMessages[siteLanguageDefault][SortAndFiltersKey],
                hash: "#sort-and-filters"
            }
        ],
        'faq': [
            {
                key: LicenseSupportExpirationKey,
                default: mainMessages[siteLanguageDefault][LicenseSupportExpirationKey],
                hash: "#License-and-Support-Expiration"
            }
        ]
    }

    const overviewContent = localization.toLanguageString(
        helpLicenseSupportExpirationOverviewSectionOneKey,
        mainMessages[siteLanguageDefault][helpLicenseSupportExpirationOverviewSectionOneKey]
    );

    const sortFilterContent = localization.toLanguageString(
        helpLicenseSupportExpirationOverviewSectionTwoKey,
        mainMessages[siteLanguageDefault][helpLicenseSupportExpirationOverviewSectionTwoKey]
    );

    const helpQAContent = localization.toLanguageString(
        helpLicenseSupportExpirationQAKey,
        mainMessages[siteLanguageDefault][helpLicenseSupportExpirationQAKey]
    );

    return (
        <>
            <PageHeader/>
            <div className={"ksm-page-container"}>
                <Row>
                    <Col xs="3">
                        <HelpMenu
                            siteLanguageDefault={siteLanguageDefault}
                            helpContent={helpContent}
                        />
                    </Col>
                    <Col xs="9">
                        <div
                            className={"k-h3 header"}
                            id={'License-and-Support-Expiration-Overview'}
                        >
                            <Text
                                textkey={LicenseSupportExpirationOverviewKey}
                                textdefault={mainMessages[siteLanguageDefault][LicenseSupportExpirationOverviewKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {overviewContent.section_one}
                            </p>
                            <p>
                                {overviewContent.section_two}
                            </p>
                            <p>
                                {overviewContent.section_three}
                            </p>
                            <ul>
                                {
                                    overviewContent.section_three_bullets?.map((bullet, bulletIndex) => {
                                        return <li key={`license-support-overview-${bulletIndex}`}>
                                            {toHtml(bullet)}
                                        </li>;
                                    })
                                }
                            </ul>
                        </div>
                        <div
                            className={"k-h3 header"}
                            id={'sort-and-filters'}
                        >
                            <Text
                                textkey={SortAndFiltersKey}
                                textdefault={mainMessages[siteLanguageDefault][SortAndFiltersKey]}
                            />
                        </div>
                        <hr/>
                        <div className="section">
                            <p>
                                {sortFilterContent.section_one}
                            </p>
                            <img src={KsmExpireLicenseSupportSort}
                                 alt={'ksm-license-support-radio-buttons'}
                            />
                            <p>
                                {sortFilterContent.section_two}
                            </p>
                            <ol>
                                <li key={'sort-filter-0'}>
                                    {toHtml(sortFilterContent.section_two_bullets[0])}
                                    <img src={KsmExpireLicenseSupportSort1}
                                         alt={'ksm-license-support-filter-dots'}
                                    />
                                </li>
                                <li key={'sort-filter-1'}>
                                    {toHtml(sortFilterContent.section_two_bullets[1])}
                                    <img src={KsmExpireLicenseSupportSort2}
                                         alt={'ksm-license-support-filter-sort'}
                                    />
                                </li>
                                <li key={'sort-filter-2'}>
                                    {toHtml(sortFilterContent.section_two_bullets[2])}
                                    <img src={KsmExpireLicenseSupportSort3}
                                         alt={'ksm-license-support-filter-contains'}
                                    />
                                </li>
                                <li key={'sort-filter-3'}>
                                    {toHtml(sortFilterContent.section_two_bullets[3])}
                                    <img src={KsmExpireLicenseSupportSort4}
                                         alt={'ksm-license-support-filter-apply'}
                                    />
                                </li>
                            </ol>
                        </div>
                        <div
                            className={"k-h3 header"}
                        >
                            <Text
                                textkey={frequentlyAskedQuestionsKey}
                                textdefault={mainMessages[siteLanguageDefault][frequentlyAskedQuestionsKey]}
                            />
                        </div>
                        <hr/>
                        <div id="License-and-Support-Expiration">
                            <h3>
                                <Text
                                    textkey={LicenseSupportExpirationKey}
                                    textdefault={mainMessages[siteLanguageDefault][LicenseSupportExpirationKey]}
                                />
                            </h3>
                            <ul>
                                {helpQAContent?.map((questionAnswer, index) => {
                                    return (
                                        <li key={"qa-" + index}>
                                            {questionAnswer?.Q}
                                            <br/>
                                            <p className="top indent">
                                                {questionAnswer?.A}
                                            </p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ExpiringLicenseSupportViewHelp;

