import React, {useContext, useState} from 'react';
import axios from 'axios';
import config from '../../config.js';
import UserContext from '../common/UserContext.js';

// react router
import {useHistory} from 'react-router-dom';

// components
import {
    ColumnMenu,
    DateIsExpiredCell,
    NoWrapCell,
    DisableRowRender,
    TextAlignMiddleCell,
    TextCapitalizeCell
} from '../common/Grid.js';
import Spinner from '../common/Spinner.js';
import {downloadFile} from '../common/utilities.js';
import {ActionIconCell, InfoIconCell, HostOrderNumberCel, PurchaseOrderNumberCell, ActivationCodeCell} from "./Cells";

// kendo react
import {Grid, GridColumn as Column, GridNoRecords} from '@progress/kendo-react-grid';
import {process} from '@progress/kendo-data-query';
import {
    plusIcon,
    minusIcon,
    chevronDownIcon,
    chevronUpIcon,
} from '@progress/kendo-svg-icons';
import {IconsContext} from '@progress/kendo-react-common';

// multilingual
import {useLocalization} from '@progress/kendo-react-intl';
import {
    hostIdKey,
    availableLicenseBrowserKey,
    cloudLicenseKey,
    licenseExpKey,
    licKey,
    downloadLicensesUpperKey,
    downloadLicenseKey,
    numberOfProductsKey,
    orderNumberKey,
    requestAvailableLicensesOrderKey,
    requestAllAvailableLicensesKey,
    prodNoKey,
    poNumberKey,
    qtyKey,
    supportExpKey,
    descriptionKey,
    licenseTypeKey,
    termKey,
    loadingPeriodsKey,
    genericEmptyGridKey,
    mainMessages, activationCodeKey
} from '../../assets/text/MultilingualText.js';
import {DownloadIcon, HyperLinkIcon} from "../common/icons";

const initialGridState = {
    take: 20,
    skip: 0,
};

export default function ViewByHost(props) {
    const {
        hostsData,
        eViewTransactionID,
        searchOption,
        searchValue
    } = props;
    const {
        accessToken,
        siteLanguageDefault
    } = useContext(UserContext);
    const localization = useLocalization();

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [selectedHost, setSelectedHost] = useState();
    const [productsData, setProductsData] = useState([]);
    const [productGridState, setProductGridState] = useState({});
    const [hostGridState, setHostGridState] = useState(initialGridState);
    const [hostState, setHostState] = useState(
        process(hostsData, initialGridState)
    );
    const [productState, setProductState] = useState(
        process(productsData, {})
    );

    // products localization labels 
    const viewByproductDescTitle = localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey]);
    const viewByProductTermTitle = localization.toLanguageString(termKey, mainMessages[siteLanguageDefault][termKey]);
    const viewByProductLicensTitle = localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey]);

    // host localization labels
    const viewByHostIdTitle = localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey]);
    const viewByHostQtyTitle = localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey]);
    const viewByHostLicenseExpTitle = localization.toLanguageString(licenseExpKey, mainMessages[siteLanguageDefault][licenseExpKey]);
    const viewByHostSupportExpTitle = localization.toLanguageString(supportExpKey, mainMessages[siteLanguageDefault][supportExpKey]);
    const viewByHostOrderNumberTitle = localization.toLanguageString(orderNumberKey, mainMessages[siteLanguageDefault][orderNumberKey]);
    const viewByHostPurchaseOrderTitle = localization.toLanguageString(poNumberKey, mainMessages[siteLanguageDefault][poNumberKey]);
    const viewByHostLicenseTitle = localization.toLanguageString(licKey, mainMessages[siteLanguageDefault][licKey]);
    const viewByHostLicenseTooltipTitle = localization.toLanguageString(downloadLicenseKey, mainMessages[siteLanguageDefault][downloadLicenseKey]);
    const viewByHostLicensesTooltipTitle = localization.toLanguageString(downloadLicensesUpperKey, mainMessages[siteLanguageDefault][downloadLicensesUpperKey]);
    const viewByHostInfoTooltipTitle = localization.toLanguageString(cloudLicenseKey, mainMessages[siteLanguageDefault][cloudLicenseKey]);
    const viewByHostInfoTooltipSubTitle = localization.toLanguageString(availableLicenseBrowserKey, mainMessages[siteLanguageDefault][availableLicenseBrowserKey]);
    const viewByHostOrderNumberTooltioTitle = localization.toLanguageString(requestAvailableLicensesOrderKey, mainMessages[siteLanguageDefault][requestAvailableLicensesOrderKey]);
    const viewByHostAssignMoreHostToolTipTitle = localization.toLanguageString(requestAllAvailableLicensesKey, mainMessages[siteLanguageDefault][requestAllAvailableLicensesKey]);
    const viewByHostProductTitle = localization.toLanguageString(prodNoKey, mainMessages[siteLanguageDefault][prodNoKey]);
    const viewByHostNoOfProductsTitle = localization.toLanguageString(numberOfProductsKey, mainMessages[siteLanguageDefault][numberOfProductsKey]);
    const defaultGridMessage = localization.toLanguageString(genericEmptyGridKey, mainMessages[siteLanguageDefault][genericEmptyGridKey]);
    const defaultLoadingMessage = localization.toLanguageString(loadingPeriodsKey, mainMessages[siteLanguageDefault][loadingPeriodsKey]);

    // convert date field data to date Object
    const filterProductData = (products) => {
        return products?.map((product) => {
            if (product.license_expiration_date) {
                product.license_expiration_date_display = new Date(product.license_expiration_date.replace(/-/g, "/"))
            }
            if (product.support_expiration_date) {
                product.support_expiration_date_display = new Date(product.support_expiration_date.replace(/-/g, "/"))
            }
            return product
        })
    }

    // handle download license file
    const handleDownloadLicense = (id) => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let payload = {"host_ids": [`${id}`]};
        setDownloadLoading(true);
        axios.post(
            config.view_by_product.DOWNLOAD_HOST_LICENSE,
            {...payload},
            {headers}
        )
            .then(async ({data}) => {
                const TIMEOUT = 2000;
                let licenses = data?.license_file_links ?? [];
                if (licenses?.length) {
                    for (let idx = 0; idx < licenses?.length; idx++) {
                        try {
                            await downloadFile(licenses[idx]);
                            await new Promise((res) => setTimeout(() => {
                                res();
                            }, TIMEOUT));
                        } catch (error) {
                            alert('Error occured while download ..', licenses[idx]);
                        }
                    }
                }
            })
            .finally(() => {
                setDownloadLoading(false);
            });
    };

    // download by license file name
    const handleDownloadLicenseByProductRow = (product) => {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        let payload = {
            "product_id": product?.product_id,
            "host_id": selectedHost?.host_id,
            "license_expiration_date": product?.license_expiration_date,
            "support_expiration_date": product?.support_expiration_date,
            "order_id": product?.order_id,
            "order_number": product?.order_number,
            "o_certificate_id": product?.o_certificate_id,
            "purchase_order": product?.purchase_order
        };
        setDownloadLoading(true);
        axios.post(
            config.view_by_product.DOWNLOAD_LICENSE,
            {...payload},
            {headers}
        )
            .then(async ({data}) => {
                const TIMEOUT = 2000;
                let licenses = data?.license_file_links ?? [];
                if (licenses?.length) {
                    for (let idx = 0; idx < licenses?.length; idx++) {
                        try {
                            await downloadFile(licenses[idx]);
                            await new Promise((res) => setTimeout(() => {
                                res();
                            }, TIMEOUT));
                        } catch (error) {
                            alert('Error occured while download ..', licenses[idx]);
                        }
                    }
                }
            })
            .finally(() => {
                setDownloadLoading(false);
            });
    };

    // get list of product using host id for child grid
    function getProductByHost(id) {
        setLoading(true);
        setProductState([]);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };

        let data = {
            transaction_id: eViewTransactionID,
            host_id: id,
            product_number: "",
            product_desc: "",
            order_number: "",
            po_number: ""
        };

        data[searchOption['id']] = searchValue.trim()

        axios.post(
            config.view_by_product.HOST_ID_SUMMARY_LIST,
            data,
            {headers}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = filterProductData(response.data || []);
                    setProductsData(data);
                    setProductState(process(data, {}));
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log("ERROR: Failed to fetch products list data", error);
            });
    }

    // ensure cell expands when clicks on toggle
    const expandChange = (event) => {
        if (!event.dataItem.expanded) {
            setSelectedHost(event.dataItem);
            setProductsData([]);
            setProductState(process([], {}));
            setLoading(true)
            getProductByHost(event.dataItem.host_id);
        }
        const filterHostState = hostState?.data?.reduce((agg, cur) => {
            if (`${cur?.host_id}${cur?.license_type}` === `${event.dataItem.host_id}${event.dataItem.license_type}`) {
                return [...agg, {
                    ...cur,
                    expanded: !event.dataItem.expanded
                }];
            } else {
                return [...agg, {
                    ...cur,
                    expanded: false
                }];
            }
        }, []);
        setHostState({
            data: filterHostState,
            total: hostState.total
        });
    };

    // filter and sort host records
    const onHostStateChange = (event) => {
        if (event.dataState.sort === undefined) {
            event.dataState.sort = [];
        }
        event.dataState.sort.unshift({
            field: 'disabled',
            dir: 'asc'
        });

        const newHostState = process(
            hostsData,
            event.dataState
        );

        setHostGridState(event.dataState);
        setHostState(newHostState);
    };

    // filter and sort product records
    const onProductStateChange = (event) => {
        if (event.dataState.sort === undefined) {
            event.dataState.sort = [];
        }
        event.dataState.sort.unshift({
            field: 'disabled',
            dir: 'asc'
        });
        const newProductState = process(
            productsData,
            event.dataState
        );
        setProductGridState(event.dataState);
        setProductState(newProductState);
    };


    // Inner Grid component view
    const ProductDetailComponent = (props) => {
        return (
            <div className='k-mt-4 k-mb-4 k-p-0'>
                <Grid
                    className='view-by-host-child-grid'
                    scrollable={"none"}
                    sortable={{
                        allowUnsort: true,
                        mode: "single"
                    }}
                    data={productState}
                    onDataStateChange={onProductStateChange}
                    rowRender={DisableRowRender}
                    {...productGridState}
                >
                    <GridNoRecords>
                        {loading ? defaultLoadingMessage : defaultGridMessage}
                    </GridNoRecords>
                    <Column
                        field="prod_num"
                        title={viewByHostProductTitle}
                        columnMenu={ColumnMenu}
                        cell={NoWrapCell}
                        width="80px"
                    />
                    <Column
                        field="description"
                        title={viewByproductDescTitle}
                        columnMenu={ColumnMenu}
                    />
                    <Column
                        field="activation_code"
                        title={localization.toLanguageString(activationCodeKey, mainMessages[siteLanguageDefault][activationCodeKey])}
                        cell={ActivationCodeCell}
                        columnMenu={ColumnMenu}
                        width="180px"
                    />
                    <Column
                        field="license_term"
                        title={viewByProductTermTitle}
                        columnMenu={ColumnMenu}
                        cell={TextCapitalizeCell}
                        width="85px"
                    />
                    <Column
                        field="locking_type"
                        title={viewByProductLicensTitle}
                        columnMenu={ColumnMenu}
                        cell={TextCapitalizeCell}
                        width="85px"
                    />
                    <Column
                        field="qty"
                        title={viewByHostQtyTitle}
                        columnMenu={ColumnMenu}
                        cell={TextAlignMiddleCell}
                        width="30px"
                    />
                    <Column
                        field="license_expiration_date_display"
                        title={viewByHostLicenseExpTitle}
                        cell={DateIsExpiredCell}
                        columnMenu={ColumnMenu}
                        width="140px"
                        filter="date"
                    />
                    <Column
                        field="support_expiration_date_display"
                        title={viewByHostSupportExpTitle}
                        cell={DateIsExpiredCell}
                        columnMenu={ColumnMenu}
                        width="140px"
                        filter="date"
                    />
                    <Column
                        field="order_number"
                        title={viewByHostOrderNumberTitle}
                        cell={(props) => <HostOrderNumberCel
                            Icon={HyperLinkIcon}
                            showActionIcon={props?.dataItem?.['qty_available'] > 0 ? true : false}
                            onHandleClick={() => {
                                history.push('/request-license?orderID=' + props.dataItem.order_id);
                            }}
                            truncate={true}
                            title={viewByHostOrderNumberTooltioTitle}
                            {...props}
                        />}
                        columnMenu={ColumnMenu}
                    />
                    <Column
                        field="purchase_order"
                        title={viewByHostPurchaseOrderTitle}
                        columnMenu={ColumnMenu}
                        width="80px"
                        cell={(props) => <PurchaseOrderNumberCell
                            truncate={true}
                            {...props}
                        />}
                    />
                    <Column
                        field="license_file_name"
                        title={viewByHostLicenseTitle}
                        width="40px"
                        sortable={false}
                        cell={(props) => {
                            if (props.dataItem['license_type'] === 'CLOUD') {
                                return <InfoIconCell title={viewByHostInfoTooltipTitle}
                                                     subtitle={viewByHostInfoTooltipSubTitle}/>;
                            }
                            return <ActionIconCell
                                Icon={DownloadIcon}
                                showActionIcon={props.dataItem['license_type']}
                                onHandleClick={async () => {
                                    await handleDownloadLicenseByProductRow(props.dataItem);
                                }}
                                title={viewByHostLicenseTooltipTitle}
                                {...props}
                            />;
                        }}
                    />
                </Grid>
            </div>
        );
    };

    const LicenseTypeCell = (props) => {
        if (props.dataItem['license_type'] === 'CLOUD') {
            return <InfoIconCell
                title={viewByHostInfoTooltipTitle}
                subtitle={viewByHostInfoTooltipSubTitle}/>;
        } else if (props.dataItem['license_type'] === 'FILE') {
            return <ActionIconCell
                Icon={DownloadIcon}
                showActionIcon={true}
                onHandleClick={() => {
                    handleDownloadLicense(props.dataItem['host_id']);
                }}
                title={viewByHostLicensesTooltipTitle}
                {...props}
            />;
        } else if (props.dataItem['host_id'] === '<unassigned>') {
            return <ActionIconCell
                Icon={HyperLinkIcon}
                showActionIcon={true}
                onHandleClick={() => {
                    history.push('/request-license');
                }}
                title={viewByHostAssignMoreHostToolTipTitle}
                {...props}
            />;
        } else {
            return <ActionIconCell
                Icon={HyperLinkIcon}
                showActionIcon={true}
                onHandleClick={() => {
                    history.push('/request-license?productID=' + props.dataItem.product_id);
                }}
                title={viewByHostAssignMoreHostToolTipTitle}
                {...props}
            />;
        }
    }

    return (
        <>
            {downloadLoading && <Spinner/>}
            <IconsContext.Provider
                value={{
                    type: 'svg',
                    icons: {
                        [plusIcon.name]: chevronDownIcon,
                        [minusIcon.name]: chevronUpIcon,
                    },
                }}
            >
                <Grid
                    className='view-by-host-grid'
                    scrollable={"none"}
                    sortable={{
                        allowUnsort: true,
                        mode: "single"
                    }}
                    total={hostState.total}
                    pageable={hostsData?.length <= initialGridState.take ? false : {
                        info: true,
                        previousNext: true,
                        pageSizes: [10, 20, 50, 100]
                    }}
                    onDataStateChange={onHostStateChange}
                    rowRender={DisableRowRender}
                    data={hostState}
                    detail={ProductDetailComponent}
                    expandField="expanded"
                    onExpandChange={expandChange}
                    {...hostGridState}
                >
                    <GridNoRecords>
                        {defaultGridMessage}
                    </GridNoRecords>
                    <Column
                        field="host_id"
                        title={viewByHostIdTitle}
                        columnMenu={ColumnMenu}
                        cell={NoWrapCell}
                        width="158px"
                    />
                    <Column
                        field="total_tagged_products_per_host"
                        title={viewByHostNoOfProductsTitle}
                        columnMenu={ColumnMenu}
                    />
                    <Column
                        field="license_type"
                        title={viewByHostLicenseTitle}
                        width="175px"
                        sortable={false}
                        cell={(props) => <LicenseTypeCell {...props} />}
                    />
                </Grid>
            </IconsContext.Provider>
        </>
    );
}