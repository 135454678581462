import React, {useContext} from 'react';
import KSMPopover from "../../common/Popovers";
import {GetResponseText} from "./GetResponseText";
import {mainMessages} from "../../../assets/text/MultilingualText";
import UserContext from "../UserContext";
import {useLocalization} from "@progress/kendo-react-intl";


export const RequestPopovers = (props) => {
    const {
        type,
        showPopover,
        popoverRef
    } = props
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();
    const texts = GetResponseText(type)
    let popoverStyle = {
        maxWidth: '35rem'
    };

    return (
        <KSMPopover
            show={showPopover}
            anchor={popoverRef && popoverRef.current}
            position={'left'}
            style={popoverStyle}
            type={texts['alertType']}
            message={<div>
                <b>
                    {localization.toLanguageString(texts['title'], mainMessages[siteLanguageDefault][texts['title']])}
                </b>
                <br/>
                {localization.toLanguageString(texts['message'], mainMessages[siteLanguageDefault][texts['message']])}
            </div>}
        />
    )
}
